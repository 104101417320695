html{
    font-size: 16px;
    position:relative;
}
body{
    margin:0.5rem;
}

.Header,.loginHeader{
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    margin: 1.34rem 0;
}


#AccContainer{
    text-align: center;
    margin: 0;
    padding:0;
    overflow: auto;
    white-space: nowrap;
}

#AccContainer::-webkit-scrollbar,#env_change_form::-webkit-scrollbar,
#envContainer::-webkit-scrollbar {
    display: none;
}

#AccContainer,.env_change_form,#envContainer{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#AccContainer > span {
    display:inline;
    margin: 0;
    padding: 0;
    margin-right: 7.01rem;
    text-align: center;
    font-weight: bold;
    font-size: 1.17rem;
}

button,input,select,textarea{
    font-size: 1rem;
}

button, input[type="submit"]{
    padding: 0.0625rem .375rem;
    border-width: 0.0625rem .0625rem; 
}

.env_change_form{
    margin: 0;
    padding:0;
    text-align: center;
    overflow:auto;
    white-space: nowrap;
}

select{
    padding:0 .125rem 0.0625rem;
    border-width: 0.0625rem;
    max-width:14rem;
}

input[type="text"], input[type="password"]{
    border-width: 0.0625rem .0625rem;
    padding:0.0625rem .125rem;
}

input[type="date"]{
    padding: 0;
    padding-left: 0.0625rem;
    border-width: 0.125rem;
}

.env_change_form > select,.env_change_form > input[type="text"] {
    margin: 0 1rem 0 0;
}


#envContainer{
    overflow-y:auto;
    margin-top:2rem;
    position:relative;
  }


.env{
    height:14rem;
    border-style: solid;
    border-width: 0.1rem 0.1rem;
    border-color: black;
    margin:0;
    padding:0;
    text-align: center;
    position:absolute;
    
}

.env_h_cat{
    margin:0;
    padding:0;
    margin:1.5rem auto;
    font-weight: bold;
    font-size: 1.17rem;
    width:90%;
    overflow-wrap: break-word;
}

.env_headers{
    margin:0;
    padding:0;
}

.env_header{
    margin-right:1.5rem;
    padding:0;
    font-weight: bold;
    font-size: 1.17rem;
    display:inline;  
}

.remEnv{
    position:absolute;
    right:0;
    top:0;
    background-color: transparent;
    border:none;
    font-size:1.5rem
}

.remEnv:hover{
    border-radius: 50%;
    border:solid;
}

.remTr{
    background-color: transparent;
    border:none;
    font-size:1.5rem
}

.remTr:hover{
    border-radius: 50%;
    border:solid;
}

.env>form{
    
    /* margin-left: 25%; */
    margin-top:5rem;
    padding:0; 
    text-align: left;
}

.env>form>input{
    margin-bottom:0.3rem;
}


.formError{
    padding-left:0.5rem;
    color:red
}

#accountDropPos{
    position:absolute;
    top:1rem;
    width:10.642rem;
    height:auto;
    margin:0;
    padding:0;
}

#accountDropPos>button{
    position:absolute;
    right:0;
}

#trButton{
    position:absolute;
    top:5rem;
}


#accountMenu {
    margin: 0;
    margin-top: 0.5rem;
    padding:0;
    border-width: 0.0625rem .0625rem;
    border-style: solid;
    border-color: grey;
    height:5rem;
    
}

#accountMenu > button:hover {
    background-color: lightgray;
}

#accountMenu > button {
width: 100%;
height:1.6667rem;
text-align: left;
background:white;
border: none;
cursor: pointer;
font: inherit;
}


#pwdForm{
    position:absolute;
    right:.4375rem;
    top:0.3rem;
    background-color: white;
    border-width: 0.0625rem .0625rem;
    border-style: solid;
    border-color: black;
    padding:0;
    margin:0;
}

.form{
    margin-left:2.39rem;
    margin-right:2rem;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    padding:0;
}


.form > input[type="password"],.form>input[type="text"] {
    margin-bottom:0.5rem;
    padding-right:13.62rem;
}

.form > input[type="checkbox"]{
    margin:0;
    margin-left: 0.5rem;
    width:0.8125rem;
    height:0.8125rem;
}

.form > span {
    margin-left:0.3rem;
}

.formClose {
    position:absolute;
    right:0;
    top:0; 
}

.trWindow {
    resize:both;
    overflow-y:auto;
    border-width: 0.0625rem .0625rem;
    border-style: solid;
    border-color: black;
    position:absolute;
    background:white;
    top:0.274rem;
    left:0.875rem;
    height:19rem;
    width: auto;
    padding:0;
    margin:0;
}

#crTrW{
    height:auto;
}

.trWindowDiv{
    cursor:move;
    background:lightblue;
    margin-bottom:1rem;
    padding:0;
    height:2.38rem;
    text-align:center;
    position:relative;
}

.trWindowHeader{
    position:relative;
    top:0.3rem;
    padding:0;
    font-size:1.5rem;
    font-weight: bold;
}

#crTrW > form{
    
    padding:0 0.786rem;
    margin:0;
}

#crTrW > form > input, #crTrW > form >select, #crTrW > form >textarea{
    margin:0;
    margin-bottom:0.625rem;
}

#crTrW > form > textarea{
    padding:0.125rem 0.125rem;
    border-width:0.0625rem 0.0625rem;
    resize:none;
}

#noTrText{
    font-size:1.5rem;
    font-weight: bold;
    margin-top:6rem;
    margin-left:5rem;
    margin-right: 5rem;
}

table{
    padding:0 1.875rem;
}

th,td{
    padding:0.0625rem 1.875rem;
}

td {
    word-wrap: break-word;
    text-align: center;
}

#dateText{
    min-width:10ch;
}

#descText{
    max-width: 50ch;
    overflow-y:scroll;
    line-height:1rem;
    max-height: 2rem;
    margin: 1rem 0;
}



.diButtons{
    text-align: center;
}

.diButtons > button {
    margin-right: 1rem;
}

.loginForm > input {
    margin-bottom:0.5rem;
}

.loginForm  > input[type="checkbox"]{
    margin:0;
    position:relative;
    margin-left: 0.5rem;
    top:0.125rem;
}

.loginForm  > span {
    margin-left:0.3rem;
    position:relative;
    top:0.125rem;
}

.loginHeader{
    text-align: left;
}






